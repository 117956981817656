import React from "react"
import { Helmet } from "react-helmet"
import useSiteMetadata from "../hooks/use-site-metadata"

export default () => {
  const { site } = useSiteMetadata()

  const metas = [
    { name: `description`, content: site.description },
    { property: `og:title`, content: site.title },
    { property: `og:description`, content: site.description },
    { property: `og:type`, content: `website` },
    { name: `twitter:card`, content: `summary` },
    { name: `twitter:creator`, content: site.author },
    { name: `twitter:title`, content: site.title },
    { name: `twitter:description`, content: site.description },
  ]

  return (
    <Helmet htmlAttributes={{ lang: `ja` }} meta={metas}>
      <title>{site.title}</title>
    </Helmet>
  )
}
