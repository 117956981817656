import { useStaticQuery, graphql } from "gatsby"

export default () => {
  const { site, topimg } = useStaticQuery(graphql`
    query {
      site {
        siteMetadata {
          title
          description
          short_name
          author
        }
      }
      topimg: file(relativePath: { eq: "pexels-albert-white-361104.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 1280) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `)

  return { site: site.siteMetadata, topimg }
}
