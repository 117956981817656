import React from "react"
import { withPrefix } from "gatsby"
import Helmet from "./helmet"
import "../app.scss"

export default ({ children }) => {
  return (
    <div>
      <img
        className="logo"
        src={withPrefix("/logo.png")}
        alt="littlehermit LLC."
      />
      <main>
        <Helmet />
        {children}
      </main>
      <footer className="footer">
        <div className="content is-size-7 has-text-centered">
          &copy;2015-2022 littlehermit LLC.
        </div>
      </footer>
    </div>
  )
}
